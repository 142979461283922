<template>
  <div>
    <a-spin :spinning="state.page_loading">
      <sdPageHeader :title="$t.cargoPrices">
        <template #buttons>
          <div class="page-header-actions">
            <a-spin :spinning="state.shipping_company_activating">
              <sdButton
                size="small"
                type="primary"
                @click="activateShippingCompany('arasmobil')"
              >
                <sdFeatherIcons
                  type="check"
                />
                Aras Kargo'yu Aktif Et
              </sdButton>
              <sdButton
                size="small"
                type="primary"
                @click="activateShippingCompany('sendeomobil')"
              >
                <sdFeatherIcons
                  type="check"
                />
                Sendeo Kargo'yu Aktif Et
              </sdButton>
			  <!--
              <sdButton
                size="small"
                type="primary"
                @click="activateShippingCompany('birgundekargo')"
              >
                <sdFeatherIcons
                  type="check"
                />
                Bir Günde Kargo'yu Aktif Et
              </sdButton>
			  -->
            </a-spin>
          </div>
        </template>
      </sdPageHeader>
      <Main>
        <sdCards headless>
          <a-table
            :data-source="state.items"
            :columns="state.columns"
            row-key="desi"
          >
            <template #desi_render="{ record }">
              <span style="color: #5A5F7D; font-weight: bold">{{ record.desi }}&nbsp;</span>
            </template>
            <template #aras_render="{ record }">
              <span style="color: forestgreen; font-weight: bold">{{ record.aras }}</span>
            </template>
            <template #sendeo_render="{ record }">
              <span style="color: forestgreen; font-weight: bold">{{ record.sendeo }}</span>
            </template>
            <template #birgundekargo_render="{ record }">
              <span style="color: forestgreen; font-weight: bold">{{ record.birgundekargo }}</span>
            </template>
          </a-table>
        </sdCards>
      </Main>
    </a-spin>
  </div>
</template>

<script>

import { defineComponent, onMounted, reactive } from 'vue'

import { Main } from '@/view/styled'

import { t } from '@/utility/language'
import restMethods from '@/utility/restMethods'

export default defineComponent({
  name: 'ContractedCargo',
  components: {
    Main,
  },
  setup() {

    const state = reactive({
      page_loading: false,
      shipping_company_activating: false,

      items: [],
      columns: [
        {
          title: t.volumeWeight,
          dataIndex: 'desi',
          align: 'center',
          slots: {
            customRender: 'desi_render',
          },
        },
        {
          title: 'Aras',
          dataIndex: 'aras',
          align: 'center',
          slots: {
            customRender: 'aras_render',
          },
        },
        {
          title: 'Sendeo',
          dataIndex: 'sendeo',
          align: 'center',
          slots: {
            customRender: 'sendeo_render',
          },
        },
        /*{
          title: 'Bir Günde Kargo',
          dataIndex: 'birgundekargo',
          align: 'center',
          slots: {
            customRender: 'birgundekargo_render',
          },
        },*/
      ],
    })

    const loadPage = () => {

      state.page_loading = true
      restMethods.postForTSoftKargoWebApp('/get-cargo-prices').then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()

          state.items = data.items
        }
      }).finally(() => {
        state.page_loading = false
      })

    }

    const activateShippingCompany = (code) => {

      state.shipping_company_activating = true
      restMethods.postForTSoftKargoWebApp('/set-cargo-to-active', { code: code }, { show_success: true }).then(() => {
        state.shipping_company_activating = false
      })

    }

    onMounted(() => {
      loadPage()
    })

    return {
      state,

      activateShippingCompany,
    }

  },
})


</script>
